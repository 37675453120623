<script lang="ts" setup>
  import VButton from '~/components/ui/VButton.vue';
  const SearchModal = defineAsyncComponent(() => import('~/components/modals/search/Index.vue'));
  const { onReveal } = createModal(SearchModal);
</script>

<template>
  <v-button
    class="search"
    appearance="text"
    color="white"
    size="normal"
    icon="search"
    title="Поиск"
    rounded
    @click="onReveal()"
  />
</template>

<style lang="scss" scoped>
  .search {
    &.v-button[data-size='normal'] {
      padding: 2px 0;
    }
  }
</style>
